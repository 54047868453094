nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 50px rgb(51, 51, 51);
  background: #373a40;
  height: 8vh;
  box-shadow: 50;
  align-items: center;
  padding: 30px;
  z-index: +10;

  .logo {
    display: flex;
    h1 {
      font-weight: bold;
      color: #19d3da;
    }

    .crow-icon {
      font-size: 2rem;
    }
  }

  .buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
    margin-left: 50%;
  }

  button {
    border: none;
    cursor: pointer;
    text-align: center;
    padding: 5px 5px;
    font-size: 1rem;
    height: 5vh;
    color: #eeeeee;
    background: #373a40;

    &:hover {
      text-decoration: underline #19d3da;
    }

    a {
      text-decoration: none;
      color: #19d3da;
      &:visited {
        color: #19d3da;
      }
    }
  }

  .resume {
    padding: 10px;
    text-decoration: none;
    color: #19d3da;
    border: 2px solid #19d3da;

    &:hover {
      background: #19d3da;
      color: #373a40;
    }
  }
}

.down-arrow {
  color: #19d3da;
  display: none;
}

@keyframes buttonSlideDown {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes buttonSlideUp {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0;
  }
}

@media screen and(max-width: 768px) {
  .buttons {
    position: absolute;
    background-color: transparent;
    flex-direction: column;
    margin-top: 40%;
    right: 10px;
    align-items: flex-end;
    display: none;
    opacity: 0;
    animation: buttonSlideUp ease 1s;
  }

  .resume {
    margin-left: 25%;
  }
  .drop-down-active {
    display: inline-block;
    opacity: 1;
    animation: buttonSlideDown ease 1s;
  }

  .drop-down {
    display: inline-block;
  }

  .down-arrow {
    display: inline-block;
  }
}
