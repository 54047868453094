.about-container {
  width: 80%;
  height: 90vh;
  padding-top: 10vh;
  margin: auto;

  .introduction {
    h3 {
      font-size: 1rem;
      color: #19d3da;
    }
    h1 {
      font-size: 3rem;
      color: #eeeeee;
    }
  }
  .about-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .text-and-button {
      width: 50%;
      padding: 1rem 0rem;
      p {
        color: #eeeeee;
        font-size: 1.5rem;
        width: 100%;

        a {
          text-decoration: none;
          color: #19d3da;

          &:visited {
            color: #19d3da;
          }
        }
      }
      .contact-btn {
        padding: 1rem 0rem;
        button {
          cursor: pointer;
          color: #19d3da;
          padding: 20px;
          text-decoration: none;
          border: 2px solid #19d3da;
          background: transparent;
          font-size: 1rem;

          &:hover {
            background: hsl(182, 79%, 48%);
            color: #373a40;
          }
        }
      }
    }
    img {
      width: 35%;
      height: auto;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 2px 2px 50px rgb(51, 51, 51);
    }
  }

  .skills {
    display: flex;
    svg {
      width: 7%;
    }
  }

  hr {
    width: 50%;
    border: 2px solid #19d3da;
  }
}

@media screen and(max-width: 768px) {
  .about-container {
    width: 70%;
  }
  .about-area {
    padding-top: 0 !important;
    flex-direction: column-reverse;
    width: 100%;

    .text-and-button {
      width: 100% !important;
      p {
        padding-top: 10px;
        font-size: 1rem !important;
      }
    }
    img {
      width: 100% !important;
      height: auto;
    }
  }

  .contact-btn {
    margin-top: 5vh !important;
    button {
      padding: 5px !important;
    }
  }

  .skills {
    display: none !important;
  }
  hr {
    display: none;
  }
}
